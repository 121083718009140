var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.teams)?_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.$options.tableHeadsUniformChooseTeams(_vm.$t),"items":_vm.teams && _vm.teams.results,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"single-select":_vm.singleSelect,"server-items-length":15,"show-select":"","item-key":"id","show-expand":"","hide-default-footer":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('p',[_vm._v("Header table")])]},proxy:true},{key:"item.league",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.league.name)+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.seasonLicence.type))+" ")]}},{key:"item.country",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.findCurrentCountry(item.country).countryName)+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"elevation-0",attrs:{"colspan":headers.length}},[_vm._v(" More info about "+_vm._s(item.name)+" ")])]}}],null,false,3381090170),model:{value:(_vm.selectedTeams),callback:function ($$v) {_vm.selectedTeams=$$v},expression:"selectedTeams"}}):_vm._e(),_c('div',{staticClass:"d-flex justify-start mt-6 mb-10"},[_c('v-btn',{staticClass:"elevation-0 mr-5",attrs:{"color":"grey lighten-2"},on:{"click":function($event){return _vm.$emit('update-step', 2)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-chevron-left ")]),_vm._v(" "+_vm._s(_vm.$t('back'))+" ")],1),_c('v-btn',{staticClass:"elevation-0",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('update-step', 4)}}},[_vm._v(" "+_vm._s(_vm.$t('next'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-chevron-right ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }