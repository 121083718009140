<template>
  <div class="payment-options">
    <div class="payment-options__wrap">
      <div class="sponsorship-sub__title">
        {{ $t('choosePaymentPlan') }}
      </div>
      <div class="payment-options__list">
        <v-radio-group v-model="paymentPlans">
          <v-radio
            :value="item.paymentPlanType"
            v-for="item in paymentsPlansByParent"
            :disabled="item.paymentPlanType === 'placements'"
            :key="item.key">
            <template v-slot:label>
              <v-list-item>
                <template v-slot:default>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.paymentPlanType === 'filters' ? $t('typeSearchFilter') : item.paymentPlanType === 'uniforms' ? $t('typeUniformSponsorship') : item.paymentPlanType === 'commercials' ? $t('typeCommercials') : $t('typeSpecialPlacement') }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.paymentPlanType === 'filters' ? $t('typeSearchFilterDesc') : item.paymentPlanType === 'uniforms' ? $t('typeUniformSponsorshipDesc') : item.paymentPlanType === 'commercials' ? $t('typeCommercialsDesc') : $t('typeSpecialPlacementDesc') }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </template>
          </v-radio>
        </v-radio-group>
      </div>
      <div class="d-flex justify-start mt-1">
        <v-btn
          class="elevation-0 mr-5"
          @click="$emit('update-step', 1)"
          color="grey lighten-2">
          <v-icon left>
            mdi-chevron-left
          </v-icon>
          {{ $t('back') }}
        </v-btn>
        <v-btn
          @click="$emit('update-step', 3)"
          class="elevation-0"
          :disabled="!paymentPlans"
          color="primary">
          {{ $t('next') }}
          <v-icon right>
            mdi-chevron-right
          </v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SponsorshipPaymentPlans',
  props: {
    list: {
      type: Array,
      required: true
    }
  },
  emits: {
    'update-step': {
      type: Number
    }
  },
  data () {
    return {
      paymentPlans: ''
    }
  },
  computed: {
    paymentsPlansByParent () {
      return this.list && this.list.filter(e => !!e.parentPlan)
    }
  }
}
</script>
<style lang="scss" scoped>
.payment-options {
  &__wrap {

  }
}
</style>
