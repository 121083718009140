<template>
  <div class="intro">
    <div class="intro-wrap">
      <div class="sponsorship-sub__title">
        {{ $t('introLeagueTitle') }}
        <p>
          {{ $t('introLeagueSubTitle') }}
        </p>
      </div>
      <div class="intro-description" v-html="$t('introLeagueDescription')"></div>
      <div class="d-flex justify-start mt-10">
        <v-btn
          @click="$emit('update-step', 2)"
          class="elevation-0"
          color="primary">
          {{ $t('next') }}
          <v-icon right small>
            mdi-chevron-right
          </v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SponsorshipIntro',
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  emits: {
    'update-step': {
      type: Number
    }
  }
}
</script>
<style lang="scss">
.intro {
  &-wrap {

  }
  &-title{
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  &-description{
    p{
      font-size: 16px;
      line-height: 1.4;
      font-weight: 500;
    }
    strong{
      font-weight: 600;
      margin-right: 5px;
    }
  }
}
</style>
