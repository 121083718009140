<template>
  <div>
    <v-data-table
      v-if="teams"
      :headers="$options.tableHeadsUniformChooseTeams($t)"
      :items="teams && teams.results"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      v-model="selectedTeams"
      :single-select="singleSelect"
      :server-items-length="15"
      show-select
      item-key="id"
      show-expand
      hide-default-footer
      class="elevation-0"
    >
      <template v-slot:top>
        <p>Header table</p>
      </template>
      <template v-slot:item.league="{item}">
        {{ item.league.name }}
      </template>
      <template v-slot:item.type="{item}">
        {{ $t(item.seasonLicence.type) }}
      </template>
      <template v-slot:item.country="{item}">
        {{ findCurrentCountry(item.country).countryName }}
      </template>

      <template v-slot:expanded-item="{ headers, item }" class="elevation-0">
        <td :colspan="headers.length" class="elevation-0">
          More info about {{ item.name }}
        </td>
      </template>
    </v-data-table>
    <div class="d-flex justify-start mt-6 mb-10">
      <v-btn
        class="elevation-0 mr-5"
        @click="$emit('update-step', 2)"
        color="grey lighten-2">
        <v-icon left>
          mdi-chevron-left
        </v-icon>
        {{ $t('back') }}
      </v-btn>
      <v-btn
        @click="$emit('update-step', 4)"
        class="elevation-0"
        color="primary">
        {{ $t('next') }}
        <v-icon right>
          mdi-chevron-right
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
import { tableHeadsUniformChooseTeams, countries } from '@/helpers'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'UniformChooseTeams',
  tableHeadsUniformChooseTeams,
  data () {
    return {
      expanded: [],
      selectedTeams: [],
      singleExpand: false,
      singleSelect: false
    }
  },
  computed: {
    ...mapGetters({
      teams: 'sponsorship/teams'
    })
  },
  created () {
    this.fetchTeams({})
  },
  methods: {
    ...mapActions({
      fetchTeams: 'sponsorship/getTeams'
    }),
    findCurrentCountry (id) {
      return countries.find(e => e.id === id)
    }
  }
}
</script>
