<template>
  <v-app id="app" class="app">
    <div class="sponsorship">
      <div class="sponsorship-wrap" v-if="sponsorshipInfo">
        <div class="sponsorship-title mb-4">
          {{ $t('sponsorshipTitle') }}
        </div>
        <v-stepper class="elevation-0" shaped v-model="step" transition="none">
          <v-stepper-header class="elevation-0">
            <v-stepper-step step="1" :complete="step > 1"></v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="2" :complete="step > 2"></v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="3" :complete="step > 3"></v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="4" :complete="step > 4"></v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="5" :complete="step > 5"></v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="6" :complete="step > 6"></v-stepper-step>
          </v-stepper-header>
          <v-stepper-items color="lightBackground" class="pt-4 elevation-0">
            <v-stepper-content step="1">
              <intro
                v-if="step === 1"
                :content="sponsorshipInfo.paymentPlansDescription[0]"
                @update-step="step = $event">
              </intro>
            </v-stepper-content>
            <v-stepper-content step="2">
              <payment-plans
                v-if="step === 2"
                :list="sponsorshipInfo.paymentPlans"
                @update-step="step = $event">
              </payment-plans>
            </v-stepper-content>
            <v-stepper-content step="3">
              <uniform-choose-teams
                @update-step="step = $event">
              </uniform-choose-teams>
            </v-stepper-content>
            <v-stepper-content step="4">

            </v-stepper-content>
            <v-stepper-content step="5">

            </v-stepper-content>
            <v-stepper-content step="6">

            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </div>
    </div>
  </v-app>
</template>
<script>
import Intro from './_Intro'
import PaymentPlans from './_PaymentOptions'
import UniformChooseTeams from './_UniformChooseTeams'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SponsorshipMainView',
  components: {
    Intro,
    PaymentPlans,
    UniformChooseTeams
  },
  head: {
    title: function () {
      return {
        inner: 'xtremo 7',
        separator: '-',
        complement: this.$t('sponsorshipTitle')
      }
    }
  },
  data () {
    return {
      step: 3
    }
  },
  computed: {
    ...mapGetters({
      sponsorshipInfo: 'sponsorship/sponsorshipInfo'
    })
  },
  created () {
    this.getSponsorshipInfo({ slug: 'live-league' })
  },
  methods: {
    ...mapActions({
      getSponsorshipInfo: 'sponsorship/getSponsorshipInfo'
    })
  }
}
</script>
<style lang="scss">
.v-stepper__step__step {
  margin-right: 0 !important;
}

.sponsorship {
  &-title {
    font-size: 28px;
    font-weight: 600;
  }
  &-sub__title{
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10px;
    p{
      margin-top: 5px;
      font-size: 16px;
      color: $silver;
      font-weight: 500;
    }
  }

  &-wrap {
    max-width: 1200px;
    margin: 0 auto;
  }

  &-title {
    text-align: center;
  }
}
</style>
